exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-2-js": () => import("./../../../src/pages/contacto-2.js" /* webpackChunkName: "component---src-pages-contacto-2-js" */),
  "component---src-pages-contacto-en-js": () => import("./../../../src/pages/contacto.en.js" /* webpackChunkName: "component---src-pages-contacto-en-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-empresa-en-js": () => import("./../../../src/pages/empresa.en.js" /* webpackChunkName: "component---src-pages-empresa-en-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-exportacion-en-js": () => import("./../../../src/pages/exportacion.en.js" /* webpackChunkName: "component---src-pages-exportacion-en-js" */),
  "component---src-pages-exportacion-js": () => import("./../../../src/pages/exportacion.js" /* webpackChunkName: "component---src-pages-exportacion-js" */),
  "component---src-pages-importacion-en-js": () => import("./../../../src/pages/importacion.en.js" /* webpackChunkName: "component---src-pages-importacion-en-js" */),
  "component---src-pages-importacion-js": () => import("./../../../src/pages/importacion.js" /* webpackChunkName: "component---src-pages-importacion-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maritimo-en-js": () => import("./../../../src/pages/maritimo.en.js" /* webpackChunkName: "component---src-pages-maritimo-en-js" */),
  "component---src-pages-maritimo-js": () => import("./../../../src/pages/maritimo.js" /* webpackChunkName: "component---src-pages-maritimo-js" */),
  "component---src-pages-medios-de-pago-js": () => import("./../../../src/pages/mediosDePago.js" /* webpackChunkName: "component---src-pages-medios-de-pago-js" */),
  "component---src-pages-nacional-en-js": () => import("./../../../src/pages/nacional.en.js" /* webpackChunkName: "component---src-pages-nacional-en-js" */),
  "component---src-pages-nacional-js": () => import("./../../../src/pages/nacional.js" /* webpackChunkName: "component---src-pages-nacional-js" */),
  "component---src-pages-payment-methods-en-js": () => import("./../../../src/pages/paymentMethods.en.js" /* webpackChunkName: "component---src-pages-payment-methods-en-js" */),
  "component---src-pages-preguntas-frecuentes-en-js": () => import("./../../../src/pages/preguntasFrecuentes.en.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-en-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntasFrecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

